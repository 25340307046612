<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="/">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>基础管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 分类树 -->
    <div class="content">
      <el-form
        inline
        label-position="right"
        label-width="60px"
        size="small"
        class="query-form"
      >
        <el-form-item style="margin-left: 15px">
          <el-tooltip content="添加" placement="top">
            <el-button type="success" size="small" @click="addClick()"
              >添加模块</el-button
            >
          </el-tooltip>
          <el-tooltip content="删除" placement="top">
            <el-button type="primary" size="small" @click="delFilter()"
              >删除选中</el-button
            >
          </el-tooltip>
        </el-form-item>
      </el-form>

      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>基础模块列表</span>
        </div>

        <el-tabs v-model="modelID" @tab-click="tabClick">
          <el-tab-pane
            v-for="item in modellist"
            :label="item.name"
            :name="item.id.toString()"
            :key="item.id"
          ></el-tab-pane>
        </el-tabs>

        <el-row :gutter="24">
          <el-col :span="12">
            <el-tree
              :data="treelist"
              show-checkbox
              default-expand-all
              node-key="id"
              ref="tree"
              highlight-current
              :check-strictly="true"
              :props="defaultProps"
              :expand-on-click-node="false"
              :render-content="renderContent"
            ></el-tree>
          </el-col>
        </el-row>
        <div style="height: 30px"></div>
      </el-card>

      <el-dialog
        :title="form.id > 0 ? '编辑模块' : '添加分模块'"
        :visible.sync="dialogVisible"
        @opened="dialogOpened()"
      >
        <el-form
          :model="form"
          ref="form"
          label-width="100px"
          :status-icon="true"
          @submit.native.prevent="submitForm(form)"
        >
          <el-tabs type="border-card">
            <el-tab-pane label="基础资料">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item prop="parentID" label="上一级">
                    <el-select
                      v-model="form.parentID"
                      clearable
                      placeholder="请选择上一级分类"
                    >
                      <el-option
                        v-for="item in treelist"
                        :label="item.label"
                        :key="item.id"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item
                prop="title"
                label="分类名称"
                :rules="[{ required: true, message: '不能为空' }]"
              >
                <el-input
                  v-model="form.title"
                  placeholder="请输入分类名称"
                ></el-input>
              </el-form-item>

              <el-form-item
                prop="code"
                label="分类编码"
                :rules="[{ required: true, message: '不能为空' }]"
              >
                <el-input
                  v-model="form.code"
                  placeholder="请输入分类编码"
                ></el-input>
              </el-form-item>

              <el-form-item label="图片">
                <div id="uploadBtn2" class="uploader uploader-warning"></div>
                <img
                  id="relativeName"
                  :src="domain + form.imgUrl"
                  style="
                    height: 40px;
                    position: relative;
                    left: 10px;
                    top: 20px;
                  "
                  v-if="form.imgUrl"
                />
              </el-form-item>

              <el-form-item prop="subTitle" label="副标题">
                <el-input
                  v-model.number="form.subTitle"
                  placeholder="请输入副标题"
                ></el-input>
              </el-form-item>

              <el-row :gutter="24">
                <el-col :span="12">
                  <el-form-item
                    prop="isEnable"
                    label="是否可用"
                    :rules="[{ required: true, message: '不能为空' }]"
                  >
                    <el-switch v-model="form.isEnable"></el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="sort" label="排序">
                    <el-input
                      v-model.number="form.sort"
                      placeholder="请输入排序"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item prop="summary" label="备注">
                <el-input
                  type="textarea"
                  v-model="form.summary"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </el-tab-pane>

            <el-tab-pane label="Seo设置">
              <el-form-item prop="seo_Title" label="Seo标题">
                <el-input
                  v-model.number="form.seo_Title"
                  placeholder="请输入seo标题"
                ></el-input>
              </el-form-item>

              <el-form-item prop="seo_Keywords" label="Seo关键词">
                <el-input
                  type="textarea"
                  v-model="form.seo_Keywords"
                  placeholder="请输入Seo关键词"
                ></el-input>
              </el-form-item>
              <el-form-item prop="seo_Description" label="Seo描述">
                <el-input
                  type="textarea"
                  v-model="form.seo_Description"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>

          <el-form-item style="padding-top: 30px">
            <el-button type="primary" :loading="submiting" native-type="submit"
              >保存</el-button
            >
            <el-button @click="dialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";

export default {
  data() {
    return {
      domain: url.getDomain(),
      form: {
        isEnable: true,
      },
      modelID: "1",
      loading: false,
      submiting: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
      treelist: [], //树结构
      dialogVisible: false,
      modellist: [], //模块列表
    };
  },
  methods: {
    // 获取模块
    getModelList: function () {
      var _this = this;
      var link = url.getSys_BasicData("GetAllBasicData");
      $.get(link, {}, (data) => {
        _this.modellist = data;
      });
    },
    // 获取树结构
    getTreeList: function () {
      var _this = this;
      var modelid = _this.modelID;
      var link = url.getSys_BasicData("GetBaseTree");
      $.get(link, { modelid: modelid }, (data) => {
        _this.treelist = data;
      });
    },
    //tab切换
    tabClick: function () {
      (this.tableData = []), this.getTreeList();
    },
    // 删除
    delFilter: function () {
      var _this = this;
      var link = url.getSys_BasicData("Delete_BasicData");
      var idlist = this.$refs.tree.getCheckedKeys().toString();
      if (!idlist) {
        _this.$message({
          type: "error",
          message: "请选择选项",
        });
        return;
      }

      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { idList: idlist }, (res) => {
            if (res.status) {
              _this.getTreeList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    remove: function (node, data) {
      var _this = this;
      var link = url.getSys_BasicData("Delete_BasicData");
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { idList: data.id }, (res) => {
            if (res.status) {
              _this.getTreeList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //编辑处理
    edit(node, data) {
      var _this = this;
      _this.dialogVisible = true;
      var id = data.id;
      var link = url.getSys_BasicData("GetModel_BasicData");
      $.get(link, { id: id }, (data) => {
        _this.form = data;
      });
      return false;
    },
    // 添加
    addClick: function () {
      this.form = {};
      this.form.isEnable = true;
      this.dialogVisible = true;
    },
    // 上传
    dialogOpened() {
      var _this = this;
      // 上传图片
      if ($("#uploadBtn2 input").length == 0) {
        $("#uploadBtn2").uploader({
          url: url.getUpload(),
          text: "上传图片",
          fileExts: "jpg;png",
          handleType: "0",
          maxSize: 1024 * 1024 * 1,
          onSuccess: function (data) {
            var src = url.getDomain() + data.relativeName;
            _this.$set(_this.form, "imgUrl", data.relativeName);
          },
        });
      }
    },
    // 保存
    submitForm: function (formName) {
      var _this = this;
      var link = url.getSys_BasicData("Save_BasicData");
      var info = _this.form;
      if (info.id > 0 && info.parentID == info.id) {
        _this.$message({
          type: "error",
          message: "父类不可以是自己",
        });
        return;
      }
      info.modelid=this.modelID;
      this.$refs.form.validate((valid) => {
        if (valid) {
          _this.submiting = true;
          // 提交后台   添加或修改
          $.post(link, info, (res) => {
            _this.submiting = false;
            _this.dialogVisible = false;
            if (res.status) {
              _this.$message({
                type: "success",
                message: res.msg,
              });

              //刷新列表，刷新大类
              _this.getTreeList();
              //_this.getParentList();
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span class="pull-right">
            <el-button
              size="mini"
              type="text"
              on-click={() => this.edit(node, data)}
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.remove(node, data)}
            >
              删除
            </el-button>
          </span>
        </span>
      );
    },
  },
  mounted() {
    // 获取模块
    this.getModelList();
    // 树结构
    this.getTreeList();
  },
};
</script>

<style >
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  padding: 5px 8px 5px 3px;
}
.el-tree-node__content {
  height: 30px;
}
</style>